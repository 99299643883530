import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 700px;
        padding: ${rhythm(1)};
        padding-top: ${rhythm(1.5)};
      `}
    >
      <Link to={`/`}>
        <h3
          css={css`
            display: inline-block;
            font-style: normal;
          `}
        >
          {data.site.siteMetadata.title}
        </h3>
      </Link>
      <div css={css`
          display: block;
          margin-bottom: ${rhythm(2)};
        `}>
          Subscribe on <a href="https://www.youtube.com/channel/UC1-80NHzDszi4fQKaGM7hpw">YouTube</a><br />
          Listen on <a href="https://podcasts.apple.com/au/podcast/the-no-signal-show/id1462161176">Apple Podcasts</a>, <a href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS9GeVZyTjFLSA%3D%3D">Google Podcasts</a>, <a href="https://open.spotify.com/show/4O1ppwUfjxCX1o3F0LnRL5">Spotify</a>, or <a href="https://overcast.fm/itunes1462161176">Overcast</a>
      </div>
      {/* <Link
        to={`/about/`}
        css={css`
          float: right;
        `}
      >
        About
      </Link> */}
      {children}
    </div>
    )
}