import Typography from "typography"
import kirkhamTheme from "typography-theme-kirkham"

kirkhamTheme.headerFontFamily = [
    "Avenir Next",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
];
kirkhamTheme.headerColor = "hsla(0,0%,0%,0.9)";
kirkhamTheme.headerWeight = 500;

const typography = new Typography(kirkhamTheme)

export default typography
export const rhythm = typography.rhythm